h1, h2, h3, a {
  color: #0090df
}
a {
  font-weight: bold;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* background-color: rgba(190,223,245,.8); */
}

p {
  color: #63554b;
  font-weight: 500;
  line-height: 1.4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}